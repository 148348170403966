import {
  GET_NEWSLETTER_LIST,
   GET_WEBSITE_REQUEST_LIST
  } from "../../Services/EndPoints";
  import commonError from "../../Components/Common/Index";
import { apiCallGetQuery, apiCallPost } from "../../Services/ApiClient";

export const getWebsiteRequests = (data) => {
    return dispatch => {
      return new Promise((resolve, reject) => {
        apiCallGetQuery(GET_WEBSITE_REQUEST_LIST, data).then(response => {
          const res = response.data;
          // console.log('res getUserQueryList::::::', res);
          resolve(res);
        }).catch(error => {
          if (error?.message == 'Unable to decode data.') {
            commonError()
          } else {
            const errorMessage = error.message || error;
            console.log('getWebsiteRequestList er::::::', error);
            reject(errorMessage);
          }
        });
      });
    };
  };

  export const getNewsletterList = (data) => {
    return dispatch => {
      return new Promise((resolve, reject) => {
        apiCallPost(GET_NEWSLETTER_LIST, data).then(response => {
          const res = response.data;
          resolve(res);
        }).catch(error => {
          if (error?.message == 'Unable to decode data.') {
            commonError()
          } else {
            const errorMessage = error.message || error;
            console.log('Newsletter list er::::::', error);
            reject(errorMessage);
          }
        });
      });
    };
  }