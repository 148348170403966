import { Breadcrumb, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import {
  getSecondaryTransactionsList,
  getUnblockRequests,
  updateUnblockRequests,
} from "../../../redux/_actions/assetAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "../../Ui/Toast/Toast";
import Swal from "sweetalert2";
import { secondaryTokensTransfer } from "../../../Services/web3/ContractServices";
import Loader from "../../../Loader/Loader";
import Paginate from "../../Ui/Paginate/Paginate";

let paginationData = "";

function UnblockRequest() {
  const limit = 10;
  const dispatch = useDispatch();

  const login = useSelector((state) => state?.LoginReducer?.userData);
  const [isLoading, setLoading] = useState(false);
  const [unblockRequests, setUnblockRequests] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchUnblockRequests();
  }, [pageCount]);

  const fetchUnblockRequests = () => {
    setLoading(true);
    const data = { limit, page: pageCount };
    setTimeout(() => {
      dispatch(getUnblockRequests({ data }))
        .then((response) => {
          const res = response?.data?.data?.rows || [];
          console.log("REESSSS", res);
          if (res.length > 0) {
            res.map((item, index) => (item["key"] = index + 1));
            setUnblockRequests(res);
          } else {
            setUnblockRequests([]);
          }
          paginationData = response?.data?.meta?.pages || 0;
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    });
  };
  const handleApproval = (id, tx_hash) => {
    // proposal_id, approval_status, wallet_address
    const data = {
      secondary_market_proposal_id: id,
      unlock_tx_hash: tx_hash,
    };
    console.log("data for approval", data);
    setTimeout(() => {
      setLoading(true);

      dispatch(updateUnblockRequests({ data }))
        .then((response) => {
          fetchUnblockRequests();
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };

  const handlePageClick = (page) => {
    setPageCount(page.selected + 1);
  };
  const dataSource = unblockRequests.map((item, index) => {
    return {
      key: item.key,
      sno: (
        <div style={{ marginLeft: 15 }} className="tableIconStyle ">
          <span className="fontFamilyText">
            {(pageCount - 1) * limit + (index + 1)}
          </span>
        </div>
      ),
      name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.seller_wallet_username}</span>
        </div>
      ),
      proposal: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.secondary_proposal_data?.title}
          </span>
        </div>
      ),
      token: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.token_quantity}</span>
        </div>
      ),
      price: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.per_unit_price}</span>
        </div>
      ),
      action:
        item?.unblock_status === 1 ? (
          <Space>
            <ButtonCustom
              label="Approve"
              lytgaryBtn
              onClick={() => {
                Swal.fire({
                  title: `Approve cancel request?`,
                  icon: "question",
                  showCancelButton: true,
                  confirmButtonColor: "#d3d3d3",
                  confirmButtonText: "Yes",
                  cancelButtonText: "NO",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    // Call secondaryTokensTransfer function here
                    try {
                      setLoading(true);
                      const result = await secondaryTokensTransfer({
                        userAddress: item?.seller_tst_wallet_address,
                        tokenAddress: item?.token_address,
                        amount: item?.token_quantity,
                      });
                      console.log("RESULT", result);
                      // Call handleApproval after successful execution of secondaryTokensTransfer
                      if (result) {
                        handleApproval(item?.id, result);
                      }
                      setLoading(false);
                    } catch (error) {
                      setLoading(false);
                      console.error(
                        "Error in secondaryTokensTransfer:",
                        error.message
                      );
                    }
                  }
                });
              }}
            >
              Approve
            </ButtonCustom>
            {/* <ButtonCustom label="Reject" lytgaryBtn>
              Reject
            </ButtonCustom> */}
          </Space>
        ) : item?.unblock_status === 2 ? (
          "Approved"
        ) : item?.unblock_status === 3 ? (
          "Rejected"
        ) : (
          "In Process"
        ),
    };
  });

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Seller Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Proposal Name",
      dataIndex: "proposal",
      key: "proposal",
    },
    {
      title: "Token Quantity",
      dataIndex: "token",
      key: "token",
    },
    {
      title: "Per Unit Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <div>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>
            {/* {activeTab === "proposals" ? "Raised Quotes" : "Transactions"} */}
            Unblock Request
          </Breadcrumb.Item>
        </Breadcrumb>
        <span>
          <b>Assigned Role : </b>
          {login?.admin_user_access_data
            ? login?.admin_user_access_data?.role_name
            : "Super Admin"}
        </span>
      </div>
      <div className="commonPadding">
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="large"
        />
      </div>
      {paginationData > 1 && (
        <Paginate
          paginationData={paginationData}
          forcePage={pageCount == "" ? 0 : pageCount - 1}
          handlePageClick={(page) => handlePageClick(page)}
        />
      )}
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default UnblockRequest;
