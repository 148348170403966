import ReactPaginate from "react-paginate";

const Paginate = (props) => {
  // //console.log('paginationData:::::::', props)
  const paginationData = props?.paginationData;
  return (
    paginationData > 0 && (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "flex-end",
          justifyContent: "center",
          padding: 20,
          boxSizing: "border-box",
          width: "100%",
          // height: '100%',
        }}
      >
        <ReactPaginate
          forcePage={props.forcePage}
          activeClassName={"item active "}
          breakLabel={"..."}
          containerClassName={"pagination"}
          disabledClassName={"disabled-page"}
          nextClassName={"item next "}
          nextLabel=">"
          onPageChange={(e) => props.handlePageClick(e)}
          pageCount={paginationData}
          pageClassName={"item pagination-page "}
          pageRangeDisplayed={2}
          previousClassName={"item previous"}
          previousLabel="<"
        />
      </div>
    )
  );
};
export default Paginate;
