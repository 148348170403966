import { Breadcrumb, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWebsiteRequests } from "../../../redux/_actions/websiteRequestsActions";
import Paginate from "../../Ui/Paginate/Paginate";
import Loader from "../../../Loader/Loader";

const WebsiteRequest = () => {
  const login = useSelector((state) => state?.LoginReducer?.userData);
  const dispatch = useDispatch();
  const [websiteRequestList, setWebsiteRequestList] = useState({
    list:[],
    totalCount:0,
    page:0,
  })

  const [isLoading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  

  const columns = [
   
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render:(_, obj) => (
        obj.firstName?.trim() + " " + obj.lastName?.trim()
      )
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
    },
    
    
  ];

  const formatData = (data) => {
    return data.map((x, index) => {
        return {
            ...x,
            sno:index+1
        }
    })
  }

  const handlePageClick = (page) => {
    let data = {
      page: page.selected + 1,
      limit: 10,
      search: "",
    };
    setPageCount(page.selected + 1);

    getWebsiteRequestsList(data);
  };

  const getWebsiteRequestsList = async (data) => {
    setLoading(true);
    try {
        const res = await dispatch(getWebsiteRequests(data))
        console.log(res)    
        if(res && res.data){
            setWebsiteRequestList({
                list: formatData(res.data),
                totalCount:res.meta?.total
            })

        } else {
            setWebsiteRequestList({
                list: [],
                totalCount:0
            })
        }
    } catch (error) {
        console.log(error)
    } finally {
      setLoading(false);
    }

  }

  useEffect(() => {
    let data = {
      page: 1,
      limit: 10,
      search: "",
    }
    getWebsiteRequestsList(data)
  },[])

  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Website Requests</Breadcrumb.Item>
        </Breadcrumb>

        <span>
          <b>Assigned Role : </b>
          {login?.admin_user_access_data
            ? login?.admin_user_access_data?.role_name
            : "Super Admin"}
        </span>
      </div>
      <div className="commonPadding supportManagement">
        <Table
          columns={columns}
          dataSource={websiteRequestList.list}
          pagination={false}
          size="large"
          expandable={{
            expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.description}</p>,
            rowExpandable: (record) => record.name !== -1,
          }}
        />
      </div>

      {websiteRequestList.totalCount > 1 && (
        <Paginate
          paginationData={websiteRequestList.page}
          forcePage={pageCount == "" ? 0 : pageCount - 1}
          handlePageClick={(page) => handlePageClick(page)}
        />
      )}
      <Loader isLoading={isLoading} />
    
    </>
  );
};

export default WebsiteRequest;
