import { Breadcrumb, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNewsletterList } from "../../../redux/_actions/websiteRequestsActions";
import Paginate from "../../Ui/Paginate/Paginate.jsx";
import Loader from "../../../Loader/Loader";

const NewsletterList = () => {
  const login = useSelector((state) => state?.LoginReducer?.userData);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const [newsletterList, setNewsletterList] = useState({
    list: [],
    totalCount: 0,
    page: 0,
  });
  const [pageCount, setPageCount] = useState(1);

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const formatData = (data) => {
    return data.map((x, index) => {
      return {
        ...x,
        sno: index + 1,
      };
    });
  };

  const handlePageClick = (page) => {
    let data = {
      page: page.selected + 1,
      limit: 10,
      search: "",
    };
    setPageCount(page.selected + 1);

    getNewsletterListing(data);
  };

  const getNewsletterListing = async (data) => {
    try {
      setLoading(true);
      const res = await dispatch(getNewsletterList(data));
      console.log(res);
      if (res && res.data) {
        setNewsletterList({
          list: formatData(res.data),
          totalCount: res.meta?.total,
          page: res.meta?.pages || 0,
        });
      } else {
        setNewsletterList({
          list: [],
          totalCount: 0,
          page: 0,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let data = {
      page: 1,
      limit: 10,
      search: "",
    };
    getNewsletterListing(data);
  }, []);

  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Newsletter List</Breadcrumb.Item>
        </Breadcrumb>

        <span>
          <b>Assigned Role : </b>
          {login?.admin_user_access_data
            ? login?.admin_user_access_data?.role_name
            : "Super Admin"}
        </span>
      </div>
      <div className="commonPadding supportManagement">
        <Table
          columns={columns}
          dataSource={newsletterList.list}
          pagination={false}
          size="large"
        />
      </div>
      {newsletterList.totalCount > 1 && (
        <Paginate
          paginationData={newsletterList.page}
          forcePage={pageCount == "" ? 0 : pageCount - 1}
          handlePageClick={(page) => handlePageClick(page)}
        />
      )}
      <Loader isLoading={isLoading} />
    </>
  );
};

export default NewsletterList;
