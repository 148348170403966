import React, { useEffect, useState } from "react";
import { Breadcrumb, Space, Table } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../Loader/Loader";
import moment from "moment";
import { useDispatch } from "react-redux";
import { toast } from "../../Ui/Toast/Toast";
import Swal from "sweetalert2";
import Paginate from "../../Ui/Paginate/Paginate";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import {
  getSecondaryTransactionsList,
  updateTransactionAfterTransfer,
} from "../../../redux/_actions/assetAction";
import { secondaryTokensTransfer } from "../../../Services/web3/ContractServices";
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import InputCustom from "../../Ui/input/InputCustom";

let paginationData = "";

function SecondaryTransactionDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    id,
    projectId,
    proposalName,
    tokenAddress,
    backRoute = "/secondaryProposals",
  } = location.state || {};
  console.log("IIIIIIDDDDDDD", id);

  const [isLoading, setLoading] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const [pageCount, setPageCount] = useState(1);
  const limit = 10;

  useEffect(() => {
    if (id === undefined) {
      navigate("/secondaryProposals");
    } else {
      fetchTransactionList();
    }
  }, [id, pageCount]);

  /************************************** fetchTransactionList ****************************************************/
  const fetchTransactionList = () => {
    setLoading(true);
    const data = { limit, page: pageCount, search, proposal_id: id };
    setTimeout(() => {
      dispatch(getSecondaryTransactionsList({ data }))
        .then((response) => {
          const res = response?.data?.data?.rows || [];
          if (res.length > 0) {
            res.map((item, index) => (item["key"] = index + 1));
            setTransactionList(res);
          } else {
            setTransactionList([]);
          }
          paginationData = response?.data?.meta?.pages || 0;
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    });
  };

  const handlePageClick = (page) => {
    setPageCount(page.selected + 1);
  };

  /**********************************Transfer function ****************************************/
  const handleTransfer = (id, tx_hash) => {
    // proposal_id, approval_status, wallet_address
    const data = {
      transaction_id: id,
      mint_tx_hash: tx_hash,
    };
    console.log("data for approval", data);
    setTimeout(() => {
      setLoading(true);

      dispatch(updateTransactionAfterTransfer({ data }))
        .then((response) => {
          fetchTransactionList();
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };

  const transactionColumns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Proposal Name",
      dataIndex: "transaction_proposal_name",
      key: "transaction_proposal_name",
    },
    {
      title: "Buyer's Name",
      dataIndex: "buyer_name",
      key: "buyer_name",
    },
    {
      title: "Buyer's email",
      dataIndex: "buyer_email",
      key: "buyer_email",
    },
    {
      title: "Seller's name",
      dataIndex: "seller_name",
      key: "seller_name",
    },
    {
      title: "Seller's email",
      dataIndex: "seller_email",
      key: "seller_email",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const dataSource = transactionList.map((item, index) => {
    return {
      key: item.key,
      sno: (
        <div style={{ marginLeft: 15 }} className="tableIconStyle ">
          <span className="fontFamilyText">
            {(pageCount - 1) * limit + (index + 1)}
          </span>
        </div>
      ),
      proposal_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.secondary_proposal_data?.title}
          </span>
        </div>
      ),
      transaction_proposal_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.proposal_data?.title}</span>
        </div>
      ),
      open_positions: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.secondary_proposal_data?.relisted === 0 ? "Close" : "Open"}
          </span>
        </div>
      ),
      primary_investors: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.no_of_primary_investors}
          </span>
        </div>
      ),
      secondary_investors: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.no_of_secondary_investors}
          </span>
        </div>
      ),
      no_of_sellers: (
        <div style={{ marginLeft: 55, textTransform: "capitalize" }}>
          <span className="fontFamilyText">{item?.no_of_sellers}</span>
        </div>
      ),
      token_quantity_consume: (
        <div style={{ marginLeft: 65, textTransform: "capitalize" }}>
          <span className="fontFamilyText">
            {item?.consumed_token_quantity || "0.00"}
          </span>
        </div>
      ),
      transaction_id: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.transaction_id}</span>
        </div>
      ),
      investor_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.investor_name}</span>
        </div>
      ),
      amount: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.amount}</span>
        </div>
      ),
      date: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.date}</span>
        </div>
      ),
      buyer_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.wallet_data?.buyer_username}
          </span>
        </div>
      ),
      buyer_email: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.wallet_data?.buyer_email}
          </span>
        </div>
      ),
      seller_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.seller_wallet_data?.username}
          </span>
        </div>
      ),
      seller_email: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.seller_wallet_data?.email}
          </span>
        </div>
      ),
      action:
        item?.token_mint_status == "complete" ? (
          "Transferred"
        ) : item?.token_mint_status == "pending" ||
          item?.token_mint_status == "failed" ? (
          <Space>
            <ButtonCustom
              label="Transfer"
              lytgaryBtn
              onClick={() => {
                Swal.fire({
                  title: `Approve transfer request?`,
                  icon: "question",
                  showCancelButton: true,
                  confirmButtonColor: "#d3d3d3",
                  confirmButtonText: "Yes",
                  cancelButtonText: "NO",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    // Call secondaryTokensTransfer function here
                    try {
                      setLoading(true);
                      const result = await secondaryTokensTransfer({
                        userAddress: item?.buyer_address,
                        tokenAddress: item?.proposal_data?.token_address,
                        amount: item?.quantity_of_token,
                      });
                      console.log("RESULT", result);
                      // Call handleApproval after successful execution of secondaryTokensTransfer
                      if (result) {
                        handleTransfer(item?.id, result);
                      }
                      setLoading(false);
                    } catch (error) {
                      setLoading(false);
                      console.error(
                        "Error in secondaryTokensTransfer:",
                        error.message
                      );
                    }
                  }
                });
              }}
            >
              Transfer
            </ButtonCustom>
          </Space>
        ) : (
          <Space>
            <ButtonCustom label="Transfer" lytgaryBtn disabled={true}>
              Transfer
            </ButtonCustom>
          </Space>
        ),

      // <Link
      //   to={
      //     activeTab === "proposals"
      //       ? "/secondaryProposals/sellerDetails"
      //       : "/secondaryTransactions/details"
      //   }
      //   style={{ marginLeft: -25 }}
      //   state={
      //     activeTab === "proposals"
      //       ? {
      //           // id: item.id,
      //           proposalId: item.proposal_id,
      //           proposalName: item?.secondary_proposal_data?.title,
      //           backRoute: "/secondaryProposals",

      //           // tokenAddress: item?.token_address,
      //           // transactionId: item?.transaction_id,
      //         }
      //       : { item }
      //   }
      //   className="buttonView"
      // >
      //   <span className="fontFamilyText">
      //     View <EyeOutlined />
      //   </span>
      // </Link>
    };
  });

  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item
            onClick={() => navigate(backRoute)}
            style={{ cursor: "pointer" }}
          >
            {proposalName}
          </Breadcrumb.Item>
          {/* <Breadcrumb.Item>{item?.proposal_data?.title}</Breadcrumb.Item> */}
        </Breadcrumb>
      </div>
      <div className="commonPadding userDatils">
        <div className="searchSec back">
          <div className="backBtn">
            <button onClick={() => navigate("/secondaryProposals")}>
              <span>
                <ArrowLeftOutlined />
              </span>
              Back
            </button>
          </div>
          <InputCustom
            basicInput
            placeholder="Quick Search"
            maxLength={50}
            searchImg={<SearchOutlined />}
            inputSearch
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <Table
            dataSource={dataSource}
            columns={transactionColumns}
            pagination={false}
            size="large"
          />
        </div>
      </div>
      {paginationData > 1 && (
        <Paginate
          paginationData={paginationData}
          forcePage={pageCount == "" ? 0 : pageCount - 1}
          handlePageClick={(page) => handlePageClick(page)}
        />
      )}
      <Loader isLoading={isLoading} />
    </>
  );
}

export default SecondaryTransactionDetails;
