import { Breadcrumb, ConfigProvider, Space, Table, Tabs } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Loader/Loader";
import { toast } from "../../Ui/Toast/Toast";
import debounce from "lodash.debounce";
import Paginate from "../../Ui/Paginate/Paginate";
import InputCustom from "../../Ui/input/InputCustom";
import {
  getProposalsList,
  getSecondaryProposalsList,
  getSecondaryTransactionsList,
  updateTransactionAfterTransfer,
} from "../../../redux/_actions/assetAction";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import Swal from "sweetalert2";
import { secondaryTokensTransfer } from "../../../Services/web3/ContractServices";

let paginationData = "";

function SecondaryProposals() {
  const login = useSelector((state) => state?.LoginReducer?.userData);
  const [isLoading, setLoading] = useState(false);
  const [ProposalList, setProposalList] = useState([]);
  const [transactionProposalList, setTransactionProposalList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [localValue, setLocalValue] = useState("");
  const [activeTab, setActiveTab] = useState("proposals");

  const limit = 10;
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeTab === "proposals") {
      fetchProposalList();
    } else {
      fetchTransactionProposalList();
    }
  }, [pageCount, search, activeTab]);

  /************************************** fetchProposalList ****************************************************/
  const fetchProposalList = () => {
    setLoading(true);
    const data = { limit, page: pageCount, search };
    setTimeout(() => {
      dispatch(getSecondaryProposalsList({ data }))
        .then((response) => {
          console.log(response.data.data.rows);
          const res = response?.data?.data?.rows || [];
          if (res.length > 0) {
            res.map((item, index) => (item["key"] = index + 1));
            setProposalList(res);
          } else {
            setProposalList([]);
          }
          paginationData = response?.data?.meta?.pages || 0;
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    });
  };

  const fetchTransactionProposalList = () => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCount,
      search: search,
    };
    setTimeout(() => {
      dispatch(getProposalsList({ data }))
        .then((response) => {
          const res = response?.data?.data?.rows;

          if (response?.data?.data?.count > 0) {
            res.map((item, index) => (item["key"] = index + 1));
            setTransactionProposalList(res);
          } else {
            setTransactionProposalList();
          }
          paginationData = response?.data?.meta?.pages || 0;
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    });
  };

  /************************************** table data ****************************************************/
  const proposalColumns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      width: "12%",
    },
    {
      title: "Proposal Name",
      dataIndex: "proposal_name",
      key: "proposal_name",
    },
    {
      title: "Position",
      dataIndex: "open_positions",
      key: "open_positions",
    },
    {
      title: "No. of primary investors",
      dataIndex: "primary_investors",
      key: "primary_investors",
    },
    {
      title: "No. of secondary investors ",
      dataIndex: "secondary_investors",
      key: "secondary_investors",
    },
    {
      title: "No. of sellers",
      dataIndex: "no_of_sellers",
      key: "no_of_sellers",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Proposal Name",
      dataIndex: "proposal_name",
      key: "proposal_name",
    },
    // {
    //   title: "Market",
    //   dataIndex: "market_name",
    //   key: "market_name",
    // },
    {
      title: "Token Name",
      dataIndex: "token_name",
      key: "token_name",
    },
    {
      title: "Type of Proposal",
      dataIndex: "proposal_type",
      key: "proposal_type",
    },
    {
      title: "Total Token Quantity",
      dataIndex: "token_quantity",
      key: "token_quantity",
    },
    {
      title: "Consumed Token Quantity",
      dataIndex: "token_quantity_consume",
      key: "token_quantity_consume",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const dataSource = ProposalList.map((item, index) => {
    return {
      key: item.key,
      sno: (
        <div style={{ marginLeft: 15 }} className="tableIconStyle ">
          <span className="fontFamilyText">
            {(pageCount - 1) * limit + (index + 1)}
          </span>
        </div>
      ),
      proposal_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.secondary_proposal_data?.title}
          </span>
        </div>
      ),
      transaction_proposal_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.proposal_data?.title}</span>
        </div>
      ),
      open_positions: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.secondary_proposal_data?.relisted === 0 ? "Close" : "Open"}
          </span>
        </div>
      ),
      primary_investors: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.no_of_primary_investors}
          </span>
        </div>
      ),
      secondary_investors: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.no_of_secondary_investors}
          </span>
        </div>
      ),
      no_of_sellers: (
        <div style={{ marginLeft: 55, textTransform: "capitalize" }}>
          <span className="fontFamilyText">{item?.no_of_sellers}</span>
        </div>
      ),
      token_quantity_consume: (
        <div style={{ marginLeft: 65, textTransform: "capitalize" }}>
          <span className="fontFamilyText">
            {item?.consumed_token_quantity || "0.00"}
          </span>
        </div>
      ),
      transaction_id: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.transaction_id}</span>
        </div>
      ),
      investor_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.investor_name}</span>
        </div>
      ),
      amount: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.amount}</span>
        </div>
      ),
      date: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.date}</span>
        </div>
      ),
      buyer_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.wallet_data?.buyer_username}
          </span>
        </div>
      ),
      buyer_email: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.wallet_data?.buyer_email}
          </span>
        </div>
      ),
      seller_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.seller_wallet_data?.username}
          </span>
        </div>
      ),
      seller_email: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.seller_wallet_data?.email}
          </span>
        </div>
      ),
      action: (
        <Link
          to={"/secondaryProposals/sellerDetails"}
          style={{ marginLeft: -25 }}
          state={{
            // id: item.id,
            proposalId: item.proposal_id,
            proposalName: item?.secondary_proposal_data?.title,
            backRoute: "/secondaryProposals",

            // tokenAddress: item?.token_address,
            // transactionId: item?.transaction_id,
          }}
          className="buttonView"
        >
          <span className="fontFamilyText">
            View <EyeOutlined />
          </span>
        </Link>
      ),
    };
  });

  const dataSource2 = transactionProposalList?.map((item, index) => {
    return {
      key: item.key,
      sno: (
        <div style={{ marginLeft: 15 }} className="tableIconStyle ">
          <span className="fontFamilyText">
            {(pageCount - 1) * limit + (index + 1)}
          </span>
        </div>
      ),
      proposal_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.proposal_name}</span>
        </div>
      ),
      // market_name: (
      //   <div className="tableIconStyle">
      //     <span className="fontFamilyText">
      //       {item?.relisted === 0
      //         ? "Primary"
      //         : item?.relisted === 1
      //         ? "Secondary"
      //         : "NA"}
      //     </span>
      //   </div>
      // ),

      token_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.token_name}</span>
        </div>
      ),
      proposal_type: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.asset_type?.type_of_proposal}
          </span>
        </div>
      ),
      token_quantity: (
        <div style={{ marginLeft: 55, textTransform: "capitalize" }}>
          <span className="fontFamilyText">{item?.total_token_quantity}</span>
        </div>
      ),
      token_quantity_consume: (
        <div style={{ marginLeft: 65, textTransform: "capitalize" }}>
          <span className="fontFamilyText">
            {item?.consumed_token_quantity || "0.00"}
          </span>
        </div>
      ),
      action: (
        <Link
          to={"/secondaryTransactions/details"}
          style={{ marginLeft: -25 }}
          state={{
            id: item.id,
            projectId: item.project_id,
            proposalName: item.proposal_name,
            tokenAddress: item?.token_address,
          }}
          className="buttonView"
        >
          <span className="fontFamilyText">
            View <EyeOutlined />
          </span>
        </Link>
      ),
    };
  });

  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1);
  };

  /************************************** updateSearch ****************************************************/
  //   const updateSearch = (search) => {
  //     setLocalValue(search);
  //     debounceData(search);
  //   };

  //   /************************************** debounceData ****************************************************/
  //   const debounceData = useCallback(
  //     debounce((search) => {
  //       setSearch(search.trim());
  //       setLocalValue(search.trim());
  //       setPageCount(1);
  //     }, 1000),
  //     []
  //   );

  /************************************** handleTabChange ****************************************************/
  const handleTabChange = (key) => {
    setActiveTab(key);
    setPageCount(1); // Reset to the first page when switching tabs
    // setSearch(""); // Reset search when switching tabs
    // setLocalValue(""); // Reset local search input when switching tabs
  };

  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>
            {/* {activeTab === "proposals" ? "Raised Quotes" : "Transactions"} */}
            Secondary Marketplace
          </Breadcrumb.Item>
        </Breadcrumb>
        <span>
          <b>Assigned Role : </b>
          {login?.admin_user_access_data
            ? login?.admin_user_access_data?.role_name
            : "Super Admin"}
        </span>
      </div>
      <div className="commonPadding userDatils  tabs__body">
        <Tabs defaultActiveKey="proposals" onChange={handleTabChange}>
          <Tabs.TabPane tab="Raised Quotes" key="proposals">
            <div style={{ marginTop: 15 }}>
              <Table
                dataSource={dataSource}
                columns={proposalColumns}
                pagination={false}
                size="large"
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Secondary market transactions" key="transactions">
            <div style={{ marginTop: 15 }}>
              <Table
                dataSource={dataSource2}
                columns={columns}
                pagination={false}
                size="large"
              />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
      {paginationData > 1 && (
        <Paginate
          paginationData={paginationData}
          forcePage={pageCount == "" ? 0 : pageCount - 1}
          handlePageClick={(page) => handlePageClick(page)}
        />
      )}
      <Loader isLoading={isLoading} />
    </>
  );
}

export default SecondaryProposals;
